window.esign = window.esign || {};

esign.cacheSelectors = function() {
  esign.cache = {
    // general
    $html: $("html"),

    // navigation
    $nav: $(".main-nav__wrap")
  };
};

esign.init = function() {
  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752, 0],
    lazy: true
  });

  objectFitImages();

  esign.cacheSelectors();
  esign.headRoom();
  esign.slickSlider();
  esign.wayPoint();
  esign.subNav();
  esign.accordion();
  esign.isotope();
  esign.videoMute();
  esign.smoothScroll();
  esign.navigation();
  esign.responsiveVideos();
  esign.formAjax();
  esign.fancyBox();
  esign.animato();
  esign.imgPatterns();
  esign.popupTop();
  // esign.ipages();
  esign.fileUpload();
  esign.cookieNotification();
};

esign.cookieNotification = function() {
  window.dataLayer = window.dataLayer || [];
  var cookieNotification = document.querySelector(".js-cookie-notification");
  var cookieNotificationTriggers = document.querySelectorAll(
    ".js-cookie-notification__trigger"
  );
  var buttonCookieAll = document.querySelector(".js-cookie-all");
  var buttonCookieReject = document.querySelector(".js-cookie-reject");
  var buttonCookieCustom = document.querySelector(".js-cookie-custom");
  var checkboxAnalytics = document.querySelector(".js-cookie__analytics");
  var checkboxMarketing = document.querySelector(".js-cookie__marketing");

  window.cookieNotification = {};
  window.cookieNotification.cookieSettings = {
    analytics: false,
    marketing: false
  };

  var gtag = function() {
    dataLayer.push(arguments);
  };

  var triggerDefaultConsent = function() {
    gtag("consent", "default", {
      ad_storage:
        window.cookieNotification.cookieSettings["marketing"] === "true"
          ? "granted"
          : "denied",
      ad_user_data:
        window.cookieNotification.cookieSettings["marketing"] === "true"
          ? "granted"
          : "denied",
      ad_personalization:
        window.cookieNotification.cookieSettings["marketing"] === "true"
          ? "granted"
          : "denied",
      analytics_storage:
        window.cookieNotification.cookieSettings["analytics"] === "true"
          ? "granted"
          : "denied"
    });
  };

  triggerDefaultConsent();

  // Set different cookies
  cookieVariables = ["analytics", "marketing"];
  var updatePreferences = function() {
    for (var i = 0; i < cookieVariables.length; i++) {
      var cookieSetting = cookieVariables[i];
      var cookieName = "cookie_settings__" + cookieSetting;
      var cookieValue = esign.readCookie(cookieName);

      window.cookieNotification.cookieSettings[cookieSetting] =
        cookieValue !== undefined ? cookieValue : false;
    }
  };

  var triggerUpdateConsent = function() {
    gtag("consent", "update", {
      ad_storage:
        window.cookieNotification.cookieSettings["marketing"] === "true"
          ? "granted"
          : "denied",
      ad_user_data:
        window.cookieNotification.cookieSettings["marketing"] === "true"
          ? "granted"
          : "denied",
      ad_personalization:
        window.cookieNotification.cookieSettings["marketing"] === "true"
          ? "granted"
          : "denied",
      analytics_storage:
        window.cookieNotification.cookieSettings["analytics"] === "true"
          ? "granted"
          : "denied"
    });
  };

  var triggerGTMEvent = function() {
    window.dataLayer.push({
      event: "cookieNotification"
    });
  };

  var createCookies = function(cookies) {
    var objectKeys = Object.keys(cookies);
    for (var index = 0; index < objectKeys.length; index++) {
      var key = objectKeys[index];
      var cookieName = "cookie_settings__" + key;
      var value = cookies[key];

      esign.createCookie(cookieName, value, 365);
    }

    cookieNotification.classList.remove("show");
    cookieNotification.classList.add("hide");
    updatePreferences();
    triggerUpdateConsent();
    triggerGTMEvent();
  };

  var showCookieNotification = function() {
    checkboxAnalytics.checked =
      window.cookieNotification.cookieSettings.analytics === "true";
    checkboxMarketing.checked =
      window.cookieNotification.cookieSettings.marketing === "true";

    cookieNotification.classList.remove("hide");
    cookieNotification.classList.add("show");

    buttonCookieAll.addEventListener("click", function() {
      createCookies({ analytics: true, marketing: true });
    });

    buttonCookieReject.addEventListener("click", function() {
      createCookies({ analytics: false, marketing: false });
    });

    buttonCookieCustom.addEventListener("click", function() {
      createCookies({
        analytics: checkboxAnalytics.checked,
        marketing: checkboxMarketing.checked
      });
    });
  };

  updatePreferences();

  // check cookies
  var showCookie = false;
  for (var j = 0; j < cookieVariables.length; j++) {
    var variable = cookieVariables[j];
    var cookieName = "cookie_settings__" + variable;
    if (!esign.readCookie(cookieName)) {
      showCookie = true;
    }
  }

  if (showCookie) {
    showCookieNotification();
  }

  for (var k = 0; k < cookieNotificationTriggers.length; k++) {
    var trigger = cookieNotificationTriggers[k];
    trigger.addEventListener("click", function(e) {
      e.preventDefault();
      showCookieNotification();
    });
  }

  triggerGTMEvent();

  // check form
  var form = document.querySelector(".cookie-notification__form");
  var toggleForm = document.querySelectorAll(".js-toggle-cookie-form");
  for (var l = 0; l < toggleForm.length; l++) {
    var trigger = toggleForm[l];
    trigger.addEventListener("click", function(e) {
      e.preventDefault();
      form.classList.toggle("show");
    });
  }
};

esign.createCookie = function(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";

  document.cookie = name + "=" + value + expires + "; path=/";
};

esign.readCookie = function(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

esign.navigation = function() {
  $(".main-nav__trigger").click(function(e) {
    e.preventDefault();
    $(this)
      .next(".main-nav__wrap")
      .slideToggle("fast");
  });

  Response.crossover("width", function() {
    if (Response.band(752)) {
      esign.cache.$nav.css("display", "block");
    } else {
      esign.cache.$nav.css("display", "none");
    }
  });
};

esign.ipages = function() {
  var options = {
    responsive: true,
    autoFit: true,
    autoHeight: false,

    padding: {
      top: 30,
      left: 10,
      right: 10,
      bottom: 70
    },

    pdfUrl: "assets/images/artikel-boons-fis-mpet.pdf",
    pdfAutoCreatePages: true,
    pdfBookSizeFromDocument: true,

    zoom: 1,

    toolbarControls: [
      { type: "share", active: true },
      { type: "thumbnails", active: false },
      { type: "gotofirst", active: true },
      { type: "prev", active: true },
      { type: "pagenumber", active: true },
      { type: "next", active: true },
      { type: "gotolast", active: true },
      { type: "zoom-in", active: false },
      { type: "zoom-out", active: false },
      { type: "zoom-default", active: false },
      { type: "optional", active: false },
      { type: "download", active: true, optional: false },
      { type: "fullscreen", active: true, optional: false }
    ]
  };

  $("#flipbook").ipages(options);

  // Events
  $("#flipbook").on("ipages:ready", function(e, plugin) {
    console.log("event:ready");
  });

  $("#flipbook").on("ipages:showpage", function(e, plugin, page) {
    console.log("event:showpage [" + page + "]");
  });

  $("#flipbook").on("ipages:hidepage", function(e, plugin, page) {
    console.log("event:hidepage [" + page + "]");
  });
};

esign.fileUpload = function() {
  (function($) {
    // Browser supports HTML5 multiple file?
    var multipleSupport = typeof $("<input/>")[0].multiple !== "undefined",
      isIE = /msie/i.test(navigator.userAgent);

    $.fn.customFile = function() {
      return this.each(function() {
        var $file = $(this).addClass("custom-file-upload-hidden"), // the original file input
          $wrap = $('<div class="file-upload-wrapper">'),
          $input = $(
            '<input type="text" class="file-upload-input" placeholder="Kies een bestand..." required/>'
          ),
          // Button that will be used in non-IE browsers
          $button = $(
            '<button type="button" class="button file-upload-button">Browse</button>'
          ),
          // Hack for IE
          $label = $(
            '<label class="file-upload-button" for="' +
              $file[0].id +
              '">Browse</label>'
          );

        // Hide by shifting to the left so we
        // can still trigger events
        $file.css({
          position: "absolute",
          left: "-9999px"
        });

        $wrap.insertAfter($file).append($file, $input, isIE ? $label : $button);

        // Prevent focus
        $file.attr("tabIndex", -1);
        $button.attr("tabIndex", -1);

        $button.click(function() {
          $file.focus().click(); // Open dialog
        });

        $file.change(function() {
          var files = [],
            fileArr,
            filename;

          // If multiple is supported then extract
          // all filenames from the file array
          if (multipleSupport) {
            fileArr = $file[0].files;
            for (var i = 0, len = fileArr.length; i < len; i++) {
              files.push(fileArr[i].name);
            }
            filename = files.join(", ");

            // If not supported then just take the value
            // and remove the path to just show the filename
          } else {
            filename = $file
              .val()
              .split("\\")
              .pop();
          }

          $input
            .val(filename) // Set the value
            .attr("title", filename) // Show filename in title tootlip
            .focus(); // Regain focus
        });

        $input.on({
          blur: function() {
            $file.trigger("blur");
          },
          keydown: function(e) {
            if (e.which === 13) {
              // Enter
              if (!isIE) {
                $file.trigger("click");
              }
            } else if (e.which === 8 || e.which === 46) {
              // Backspace & Del
              // On some browsers the value is read-only
              // with this trick we remove the old input and add
              // a clean clone with all the original events attached
              $file.replaceWith(($file = $file.clone(true)));
              $file.trigger("change");
              $input.val("");
            } else if (e.which === 9) {
              // TAB
              return;
            } else {
              // All other keys
              return false;
            }
          }
        });
      });
    };

    // Old browser fallback
    if (!multipleSupport) {
      $(document).on("change", "input.customfile", function() {
        var $this = $(this),
          // Create a unique ID so we
          // can attach the label to the input
          uniqId = "customfile_" + new Date().getTime(),
          $wrap = $this.parent(),
          // Filter empty input
          $inputs = $wrap
            .siblings()
            .find(".file-upload-input")
            .filter(function() {
              return !this.value;
            }),
          $file = $(
            '<input type="file" id="' +
              uniqId +
              '" name="' +
              $this.attr("name") +
              '"/>'
          );

        // 1ms timeout so it runs after all other events
        // that modify the value have triggered
        setTimeout(function() {
          // Add a new input
          if ($this.val()) {
            // Check for empty fields to prevent
            // creating new inputs when changing files
            if (!$inputs.length) {
              $wrap.after($file);
              $file.customFile();
            }
            // Remove and reorganize inputs
          } else {
            $inputs.parent().remove();
            // Move the input so it's always last on the list
            $wrap.appendTo($wrap.parent());
            $wrap.find("input").focus();
          }
        }, 1);
      });
    }
  })(jQuery);

  $("input[type=file]").customFile();
};

esign.responsiveVideos = function() {
  $(
    'iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]'
  ).wrap('<div class="video-container"></div>');
};

esign.headRoom = function() {
  $(".page-header").headroom({
    offset: 0,
    tolerance: 5,
    classes: {
      initial: "animated"
      //"pinned": "slideDown",
      //"unpinned": "slideUp"
    }
  });
};

esign.videoMute = function() {
  $("#cta-mute").click(function() {
    if ($("#cta-mute").hasClass("muted")) {
      $("#vid").prop("muted", false);
      $(this).removeClass("muted");
      $(this).addClass("unmuted");
    } else {
      $("#vid").prop("muted", true);
      $(this).addClass("muted");
      $(this).removeClass("unmuted");
    }
  });
};

esign.smoothScroll = function() {
  $("a.smooth").click(function() {
    var target = $(this.hash);
    target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
    if (target.length) {
      $("html,body").animate(
        {
          scrollTop: target.offset().top - 50
        },
        1000
      );
      return false;
    }
  });
};

esign.fancyBox = function() {
  $(".fancybox").fancybox({
    openEffect: "elastic",
    closeEffect: "none",
    loop: false,
    helpers: {
      overlay: {
        locked: false
      }
    },
    afterShow: function() {
      if ("ontouchstart" in document.documentElement) {
        $(".fancybox-nav").css("display", "none");
        $(".fancybox-wrap").swipe({
          swipe: function(event, direction) {
            if (direction === "left" || direction === "up") {
              $.fancybox.next();
            } else {
              $.fancybox.prev();
            }
          }
        });
      }
    },
    tpl: {
      closeBtn:
        '<a title="Sluiten" class="fancybox-item fancybox-close" href="javascript:;"></a>',
      error:
        '<p class="fancybox-error">De gevraagde inhoud kan niet worden.<br/>Probeer later opnieuw.</p>',
      next:
        '<a title="Volgende" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
      prev:
        '<a title="Vorige" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
    }
  });

  $(".instructions.fancybox").fancybox({
    type: "iframe",
    fullscreen: true,
    fitToView: true,
    autoSize: false,
    closeClick: false,
    openEffect: "none",
    closeEffect: "none",
    helpers: {
      overlay: {
        locked: false
      }
    },
    afterShow: function() {
      if ("ontouchstart" in document.documentElement) {
        $(".fancybox-nav").css("display", "none");
        $(".fancybox-wrap").swipe({
          swipe: function(event, direction) {
            if (direction === "left" || direction === "up") {
              $.fancybox.next();
            } else {
              $.fancybox.prev();
            }
          }
        });
      }
    },
    tpl: {
      closeBtn:
        '<a title="Sluiten" class="fancybox-item fancybox-close" href="javascript:;"></a>',
      error:
        '<p class="fancybox-error">De gevraagde inhoud kan niet worden.<br/>Probeer later opnieuw.</p>',
      next:
        '<a title="Volgende" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
      prev:
        '<a title="Vorige" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
    }
  });

  // $('.js-pdf-popup').hide();

  if (Cookies.get("emailInputted") != "true") {
    $(".js-pdf-popup-trigger").hide();
  }

  $(".js-pdf-popup-trigger").click(function(e) {
    e.preventDefault();
    $(".js-pdf-popup").show();
    $(".js-pdf-popup").css("opacity", "1");
    $(".js-pdf-popup").css("pointer-events", "all");
  });

  $(".js-pdf-popup__close").click(function(e) {
    e.preventDefault();
    $(".js-pdf-popup").hide();
    $(".js-pdf-popup").css("opacity", "0");
    $(".js-pdf-popup").css("pointer-events", "none");
  });

  // document.getElementById("brochureForm").onsubmit = function() {
  //   brochureSubmitted();
  // };

  function brochureSubmitted() {
    $(".js-pdf-popup-trigger").show();
    Cookies.set("emailInputted", "true");
  }
};

esign.wayPoint = function() {
  $(function() {
    var UP = "up",
      DOWN = "down";

    $(".home-banner__logo").waypoint(
      function(direction) {
        if (direction == DOWN) {
          $(".page-header").addClass("header-active");
        } else {
          $(".page-header").removeClass("header-active");
        }
      },
      {
        offset: "7px"
      }
    );

    function loadFrame() {
      $(".spotlight__image").addClass("swipe-in");
    }
    window.onload = setTimeout(loadFrame, 1000);
  });
};

esign.subNav = function() {
  if (Response.band(752)) {
    $(".has--subnav")
      .hover(function() {
        //When trigger is hovered...

        //$('.primary-nav').addClass('nav-active');

        //Following events are applied to the subnav itself (moving subnav up and down)
        $(this)
          .parent()
          .find("ul.primary-nav")
          .slideDown("fast")
          .show(); //Drop down the subnav on click
        $(".has--subnav").addClass("sub-hover");

        $(this)
          .parent()
          .hover(
            function() {},
            function() {
              $(this)
                .parent()
                .find("ul.primary-nav")
                .slideUp("fast"); //When the mouse hovers out of the subnav, move it back up
              $(".has--subnav").removeClass("sub-hover");
            }
          );

        //for my second subnav menu

        $(this)
          .parent()
          .find("ul.primary-nav")
          .find("li .secondary-nav")
          .parent()
          .hover(
            function() {
              $(this)
                .find(".secondary--part")
                .slideDown("fast")
                .show(); //Drop down the subnav2 on hover
            },
            function() {
              $(this)
                .find(".secondary--part")
                .slideUp("fast"); //Drop down the subnav2 on hover
            }
          );
        //Following events are applied to the trigger (Hover events for the trigger)
      })
      .hover(function() {}, function() {});
  } else {
    $(".has--subnav").click(function(e) {
      e.preventDefault();
      $(".primary-nav").slideToggle("fast");

      $(".primary-nav li").click(function(e) {
        $(this)
          .find(".secondary--part")
          .slideToggle("fast");
      });
    });
  }
};

esign.accordion = function() {
  $(function() {
    $(".accordion-trigger").click(function(e) {
      e.preventDefault();

      var $this = $(this),
        $target = null;

      // get target
      if ($this.is("a")) {
        $target = $($this.attr("href"));
      } else if ($this.is("label")) {
        $target = $("#" + $this.attr("for")).closest(".accordion-content");
      } else if ($this.is("div")) {
        $target = $($this.data("href"));
      }

      // toggle target
      if ($target !== null) {
        $this.toggleClass("active");
        $target.toggle("fast");

        if ($target.is(":visible")) {
          //$target.slideUp('fast');

          if ($this.data("meer info")) {
            $this.text($this.data("more"));
          }
        } else {
          $target.slideDown("fast");

          if ($this.data("less")) {
            $this.text($this.data("less"));
          }
        }
      }
    });
  });
};

esign.slickSlider = function() {
  $(".home-blog").slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow:
      "<button type='button' class='slick-prev'><i class='icon icon-arrow_small'></i></button>",
    nextArrow:
      "<button type='button' class='slick-next'><i class='icon icon-arrow_small'></i></button>",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          //centerMode: true,
          //centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });

  $(".home-clients").slick({
    infinite: true,
    autoplay: true,
    arrows: false,
    slidesToShow: 5,
    autoplaySpeed: 0,
    speed: 5000,
    slidesToScroll: 1,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          //centerMode: true,
          //centerPadding: '40px',
          slidesToShow: 2
        }
      }
    ]
  });

  $(".relevant-products").slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow:
      "<button type='button' class='slick-prev'><i class='icon icon-arrow_small'></i></button>",
    nextArrow:
      "<button type='button' class='slick-next'><i class='icon icon-arrow_small'></i></button>",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 1
        }
      }
    ]
  });
};

esign.isotope = function() {
  if ($(".isotope-grid").length) {
    $(".isotope-grid").isotope({
      itemSelector: ".isotope-item",
      layoutMode: "masonry"
    });
  }

  if ($(".isotope-grid__blog").length) {
    $(".isotope-grid__blog").isotope({
      itemSelector: ".isotope-item",
      layoutMode: "fitRows"
    });
  }
};

// Ajax newsletter subscribe
esign.formAjax = function() {
  $(".form-ajax").submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr("action"), $form.serializeArray(), function(data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find(".result").html(data.result);
        $form.find('button, input[type="submit"]').removeAttr("disabled");
      }
    });

    e.preventDefault();
    return false;
  });
};

esign.animato = function() {
  var itemQueue = [];
  var delay = 200;
  var queueTimer;

  function processItemQueue() {
    if (queueTimer) return;
    queueTimer = window.setInterval(function() {
      if (itemQueue.length) {
        $(itemQueue.shift()).addClass("revealed");
        processItemQueue();
      } else {
        window.clearInterval(queueTimer);
        queueTimer = null;
      }
    }, delay);
  }

  $(".over-ons .animato").waypoint(
    function() {
      itemQueue.push(this.element);
      processItemQueue();
    },
    {
      offset: "92%"
    }
  );
};

esign.imgPatterns = function() {
  checkPattern(1, [
    "large--two-thirds",
    "large--one-third",
    "large--one-third"
  ]);

  function checkPattern(removeMarginFromPatternElNr, pattern) {
    var classes = [];
    var $els = [];
    var classesIndex = null;

    $(".detail")
      .children()
      .each(function(i, $el) {
        classes.push($el.classList[1]);
        $els.push($el);
      });

    for (var i = 0; i < classes.length; i++) {
      if (
        classes[i] === pattern[0] &&
        classes[i + 1] === pattern[1] &&
        classes[i + 2] === pattern[2]
      ) {
        classesIndex = i + removeMarginFromPatternElNr;
      }
    }

    $($els[classesIndex])
      .children(".mb40")
      .css("margin-bottom", "0");
  }
};

esign.popupTop = function() {
  if (Cookies.get("boons_info") != "true") {
    $("#topbanner").css("display", "block");
    $("#topbanner-button").on("click", function() {
      $("#topbanner").css("display", "none");
      Cookies.set("boons_info", "true");
    });
  }
};

// Initialize on docready
$(esign.init);
